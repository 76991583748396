<template>
    <router-view />
</template>
<script>
export default {
    name: "GappPublic",
    created: function () {},
}
</script>
<style scoped></style>
