<template>
    <div>
        <p>{{ text.errorPage.initPara }}</p>
        <ol type="1" :style="cssVars">
            <!-- eslint-disable vue/no-v-html -->
            <li
                v-if="parsedText(text.errorPage.helpOpt1)"
                v-html="parsedText(text.errorPage.helpOpt1)"></li>
            <li
                v-if="parsedText(text.errorPage.helpOpt2)"
                v-html="parsedText(text.errorPage.helpOpt2)"></li>
            <li
                v-if="parsedText(text.errorPage.helpOpt3)"
                v-html="parsedText(text.errorPage.helpOpt3)"></li>
            <li
                v-if="parsedText(text.errorPage.helpOpt4)"
                v-html="parsedText(text.errorPage.helpOpt4)"></li>
            <!-- eslint-enable vue/no-v-html -->
        </ol>
        <p>{{ text.errorPage.endPara }}</p>
    </div>
</template>
<script>
export default {
    name: "ErrorSection",
    computed: {
        cssVars: {
            get() {
                return {
                    "--img-height": this.design.graphicHeight
                        ? this.design.graphicHeight + "px"
                        : "auto",
                    "--img-margin": this.design.graphicMargin
                        ? this.design.graphicMargin + "px"
                        : "auto",
                    "--link-color": this.linkColor,
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        linkColor: {
            get() {
                if (!this.design) return null

                return this.design.theme.linkColor
                    ? this.design.theme.linkColor
                    : this.design.theme.accentColor
            },
        },
        params: {
            get() {
                var params = "?"

                if (this.$route.params.originalPath)
                    params += this.$route.params.originalPath.split("?")[1]
                if (this.$route.params.reason)
                    params += "&reason=" + this.$route.params.reason

                return params.length > 1
                    ? params + "&form=" + this.$route.params.id
                    : "?form=" + this.$route.params.id
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
    },
    methods: {
        parsedText(txt) {
            if (txt == null || txt.length == 0) return null
            console.log(this.text)
            txt = txt.replace(
                "{CLICKZONE}",
                '<a href="#" class="a2" onclick="location.reload();">' +
                    this.text.errorPage.clickZone +
                    "</a>",
            )
            txt = txt.replace(
                "{HELPLINK}",
                "https://www.moodybible.org/donate/question-about-giving/giving-app" +
                    encodeURI(this.params),
            )

            return txt
        },
    },
}
</script>
<style>
html {
    overflow-y: scroll;
}
</style>
<style scoped>
ol a,
ol .a2 {
    color: var(--link-color);
}

ol a:hover,
ol .a2:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */

    a {
        color: #003b5c;
    }

    .a2 {
        color: #003b5c;
    }
}
</style>
