import { GAPP_GLOBALS } from "./globals.js"
import { TraceContext, init as tracingInit } from "./tracing.js"
import { RecaptchaError } from "./errors/index.js"
tracingInit()

import Vue from "vue"
import Vuex from "vuex"
import router from "./router.js"
import axios from "axios"
import { init as vuelidatorInit } from "./vuelidator.js"

let vuelidator
Vue.use(Vuex)

const traceparent = TraceContext.getRootContext()
const defaultAxiosOptions = {
    baseURL: GAPP_GLOBALS.BASE_PATH,
    headers: {
        traceparent: traceparent,
    },
}

const httpClient = axios.create(defaultAxiosOptions)

import { marked } from "marked"
import domPurify from "dompurify"
function initialState() {
    return {
        adminView: null,
        bankAccountType: {
            isSavingsAcct: null,
            isCompanyAcct: null,
        },
        ccType: null,
        countryList: [],
        customer: null,
        echkDisabled: false,
        featuredPremium: null,
        form: null,
        giftType: null,
        isCountriesLoaded: false,
        isEmbedded: false,
        isProvincesLoaded: false,
        isStatesLoaded: false,
        isOrgGift: false,
        isPremiumSelected: false,
        premiumUnavailable: false,
        provinceList: null,
        recaptchaEnabled: null,
        receiptData: null,
        recipe: null,
        searchingZip: false,
        stateList: null,
        stripeBankPayment: null,
        stripeError: null,
        stripePaymentIntent: null,
        stripePaymentMethod: {
            stripe: null,
            amount: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            paymentMethodData: null,
            fetchedPaymentMethodData: null,
        },
        stripeSetupIntent: null,
        stripeCardNumberEvent: {
            brand: "",
            complete: false,
            elementType: "cardNumber",
            empty: true,
            error: null,
            value: null,
        },
        stripeCardExpiryEvent: {
            brand: "",
            complete: false,
            elementType: "cardExpiry",
            empty: true,
            error: null,
            value: null,
        },
        stripeCardCvcEvent: {
            brand: "",
            complete: false,
            elementType: "cardCvc",
            empty: true,
            error: null,
            value: null,
        },
        submitDisabled: false,
        submitError: false,
        text: JsonText,
        transaction: {
            TxId: 0,
            GiftSource: null,
            Campaign: null,
            Appeal: null,
            RecurrenceCap: null,
            Donor: {
                FirstName: null,
                LastName: null,
                Organization: null,
                Address1: null,
                Address2: null,
                City: null,
                State: null,
                Postal: null,
                Country: "US",
                Phone: null,
                Email: null,
                SourceCode: null,
                MarketingOptIn: false,
            },
            Payment: {
                CardType: null,
                CcExpireDate: null,
                RoutingNumber: null,
                TenderType: null,
                EftAccountType: null,
                AcctLastFour: null,
                Vendor: null,
                VendorRefNumber: null,
                VendorDonorId: null,
                PayToken: null,
            },
            CardNumber: null,
            Cvv: null,
            AccountNumber: null,
            Premiums: [],
            Splits: [
                {
                    Designation: null,
                    Amount: null,
                },
            ],
            Marketing: {
                UtmContent: null,
                UtmMedium: null,
                UtmSource: null,
                UtmTerm: null,
                UtmCampaign: null,
            },
            ExtendedData: {},
            //Token: null,
            DDGI: {},
        },
        transactionListProperties: ["Premiums", "Splits"],
        variantKeys: {},
        zipData: null,
    }
}
const store = new Vuex.Store({
    state: initialState(),
    getters: {
        compileMarkdown: () => (input, removeParagraph) => {
            var markdown = domPurify.sanitize(input, { ALLOWED_TAGS: ["u"] })
            //domPurify borks the encoding of the '>' character which we need to not be borked for blockquote markdown
            //so this is the solution I came up with
            while (markdown.includes("&gt;")) {
                markdown = markdown.replace("&gt;", ">")
            }
            // for single-line markdown we don't always want to wrap in <p> tag (eg. premium checkbox text)
            return !removeParagraph
                ? marked(markdown)
                : marked(markdown).replace("<p>", "").replace("</p>", "")
        },
        design: (state) => {
            if (state.recipe.design == null) {
                router.replace("/404")
            }

            return state.recipe.design
        },
        formLanguage: (state) => {
            return state.recipe.features.language
                ? state.recipe.features.language
                : "english"
        },
        getCardType: (state) => (getLongDescription) => {
            // Let's not bother performing all these regex tests with incomplete entries
            if (
                !state.transaction.CardNumber ||
                state.transaction.CardNumber.length < 15 ||
                (state.transaction.CardNumber.length == 15 &&
                    state.transaction.CardNumber[0] != "3")
            )
                return null

            var defaultFormat = /(\d{1,4})/g
            var defaultInputFormat = /(?:^|\s)(\d{4})$/
            var cards = [
                {
                    type: "DI",
                    description: "Discover",
                    pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                    format: defaultFormat,
                    inputFormat: defaultInputFormat,
                },
                {
                    type: "MC",
                    description: "MasterCard",
                    pattern:
                        /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
                    format: defaultFormat,
                    inputFormat: defaultInputFormat,
                },
                {
                    type: "VI",
                    description: "VISA",
                    pattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
                    format: defaultFormat,
                    inputFormat: defaultInputFormat,
                },
                //{
                //    type: 'DINERS',
                //    pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                //    format: defaultFormat,
                //    inputFormat: defaultInputFormat
                //}
            ]

            var amex = {
                type: "AMEX",
                description: "American Express",
                pattern: /^3[47][0-9]{13}$/,
                format: defaultFormat,
                inputFormat: defaultInputFormat,
            }

            // Check for AMEX separately
            if (state.transaction.CardNumber.length == 15) {
                return amex.pattern.test(state.transaction.CardNumber)
                    ? getLongDescription
                        ? amex.description
                        : amex.type
                    : false
            }

            for (var i = 0; i < cards.length; i++) {
                if (cards[i].pattern.test(state.transaction.CardNumber)) {
                    return getLongDescription
                        ? cards[i].description
                        : cards[i].type
                }
            }

            return false
        },
        premium: (state) => {
            return state.featuredPremium || null
        },
        $v() {
            if (!vuelidator) {
                vuelidator = vuelidatorInit(store)
            }
            return vuelidator.$v
        },
    },
    mutations: {
        clearReceipt: (state) => {
            state.receiptData = null
        },
        loadCountries: (state, countryList) => {
            state.countryList = countryList
            state.isCountriesLoaded = true
        },
        loadStates: (state, stateList) => {
            state.stateList = stateList
            state.isStatesLoaded = true
        },
        loadProvinces: (state, provinceList) => {
            state.provinceList = provinceList
            state.isProvincesLoaded = true
        },
        loadRecipe: (state, form) => {
            state.form = form
            state.transaction.ExtendedData["FORM_SOURCE"] = form.id
            state.recipe = JSON.parse(form.recipe)
            state.recipe.design.theme = JSON.parse(form.theme)
            state.recipe.premiums = []
            form.premiums.forEach((p) => {
                p.content = JSON.parse(p.content)
                p.layout = JSON.parse(p.layout)
                state.recipe.premiums.push(p)
            })
            state.alias = form.alias

            if (state.recipe.design.pageTitle)
                document.title = state.recipe.design.pageTitle

            state.transaction.Splits[0].Designation =
                state.recipe.codes.designation
            state.transaction.Campaign = state.recipe.codes.intvCd
            state.transaction.Appeal = state.recipe.codes.motivationCd
            state.transaction.GiftSource = state.recipe.codes.giftSource
            state.transaction.Donor.SourceCode = state.recipe.codes.srcCd
            state.transaction.ExtendedData["CORPGIFT"] =
                state.recipe.codes.corpgift
            document.body.style.backgroundColor = state.isEmbedded
                ? "transparent"
                : state.recipe.design.theme.pageBackground
        },
        loadPremium(state, params) {
            //set selected premium from param p or default premium in state
            state.featuredPremium =
                (params.p &&
                    state.recipe.premiums.find(
                        (prem) => prem.code == params.p,
                    )) ||
                (state.recipe.premiums && state.recipe.premiums[0])
        },
        loadVariantKeys(state, params) {
            //set variant
            state.variantKeys = {}
            const variants = state.recipe.design.variants
            if (variants && variants.length) {
                const variant =
                    params.v &&
                    variants.some(
                        (v) => v.code.toLowerCase() == params.v.toLowerCase(),
                    )
                        ? variants.find(
                              (v) =>
                                  v.code.toLowerCase() ==
                                  params.v.toLowerCase(),
                          )
                        : variants.find((v) => v.default)
                variant.keys.forEach(
                    ({ key, value }) =>
                        (state.variantKeys[key.toUpperCase()] = value),
                )
            }
        },
        loadQueryParams(state, params) {
            var correctedParams = {}
            for (let [key, value] of Object.entries(params)) {
                // Convert any utm params to pascal case
                if (key.length > 3 && key.substr(0, 3).toLowerCase() === "utm")
                    correctedParams[
                        "Utm" +
                            key.substr(4, 1).toUpperCase() +
                            key.substr(5).toLowerCase()
                    ] = value
                // toLowerCase everything else
                else if (key.length > 0 && key.trim().length > 0)
                    correctedParams[key.toLowerCase().trim()] = value
            }

            if (correctedParams.appeal)
                state.transaction.Appeal = correctedParams.appeal

            var validUtms = [
                "UtmContent",
                "UtmMedium",
                "UtmSource",
                "UtmTerm",
                "UtmCampaign",
            ]
            validUtms.forEach((k) => {
                if (correctedParams[k])
                    state.transaction.Marketing[k] = correctedParams[k]
            })

            // TODO: Remove after migrating to NPSP
            if (["CS", "NPSP"].includes(correctedParams.backend_override)) {
                state.transaction.ExtendedData["BACKEND_SYSTEM"] =
                    correctedParams.backend_override
            }
        },
        loadZipData(state, zipData) {
            state.zipData = zipData
            if (zipData) {
                if (
                    zipData.cities.length > 1 &&
                    zipData.cities.some((c) => c.isPreferred)
                )
                    state.transaction.Donor.City = zipData.cities.find(
                        (c) => c.isPreferred,
                    ).cityName
                else if (zipData.cities.length > 0)
                    state.transaction.Donor.City = zipData.cities[0].cityName

                if (zipData.state) state.transaction.Donor.State = zipData.state
            }

            state.searchingZip = false
            state.cityAndStateNowVisible = true
        },
        resetZipData(state) {
            state.zipData = null
        },
        resetState(state) {
            Object.assign(state, initialState())
        },
        setPaymentIntent(state, data) {
            if (data.statusText == "OK" || data.status == 200) {
                state.stripePaymentIntent = data.data
            }
        },
        setPaymentData(state, data) {
            state.transaction.Payment.Vendor = data.Vendor
            state.transaction.Payment.VendorRefNumber = data.VendorRefNumber
            state.transaction.Payment.VendorDonorId = data.VendorDonorId
            state.transaction.Payment.PayToken = data.PayToken
            state.transaction.Payment.AcctLastFour = data.AcctLastFour
            state.transaction.Payment.TenderType = data.TenderType

            state.transaction.Payment.CardType = data.CardType
            state.transaction.Payment.CcExpireDate = data.CcExpireDate

            state.transaction.Payment.RoutingNumber = data.RoutingNumber
            state.transaction.Payment.EftAccountType = data.EftAccountType
        },
        setAdminView(state) {
            state.adminView = true
        },
        setCustomer(state, data) {
            state.customer = data.data
        },
        setIsEmbedded(state, data) {
            state.isEmbedded = data
        },
        setReceipt(state, data) {
            state.receiptData = data
        },
        setStripeSetupIntent(state, data) {
            state.stripeSetupIntent = data.data
        },
        setStripeElementEvent(state, elementEvent) {
            let prop
            if (elementEvent.elementType == "cardNumber") {
                prop = state.stripeCardNumberEvent
            } else if (elementEvent.elementType == "cardExpiry") {
                prop = state.stripeCardExpiryEvent
            } else if (elementEvent.elementType == "cardCvc") {
                prop = state.stripeCardCvcEvent
            }
            if (prop) {
                prop.brand = elementEvent.brand
                prop.complete = elementEvent.complete
                prop.elementType = elementEvent.elementType
                prop.empty = elementEvent.empty
                prop.error = elementEvent.error
                prop.value = elementEvent.value
            }
        },
        updatePremiumUnavailable(state, value) {
            state.premiumUnavailable = value
        },
        updateTransaction(state, update) {
            if (update.propLocation.length == 1) {
                state.transaction[update.propLocation[0]] = update.value
            } else if (update.propLocation.length == 2) {
                var isListProperty = state.transactionListProperties.includes(
                    update.propLocation[0],
                )
                if (isListProperty)
                    // currently, we are not leveraging the list functionality of listed properties,
                    // so we are only ever using the first element in those lists
                    state.transaction[update.propLocation[0]][0][
                        update.propLocation[1]
                    ] = update.value
                else if (update.propLocation[0] == "ExtendedData") {
                    if (!update.value) {
                        delete state.transaction.ExtendedData[
                            update.propLocation[1]
                        ]
                    } else {
                        state.transaction.ExtendedData[update.propLocation[1]] =
                            update.value
                    }
                } else {
                    state.transaction[update.propLocation[0]][
                        update.propLocation[1]
                    ] = update.value
                }
            }
        },
        updateDdrpStation(state, value) {
            state.transaction.DDGI = value
                ? {
                      ACall: value.ddrpACall,
                      GroupCall: value.groupCall,
                      Frequency: value.frequency,
                  }
                : {}
        },
        updateGiftType(state, value) {
            state.giftType = value
            switch (value) {
                case "single":
                    state.transaction.RecurrenceCap = 1
                    delete state.transaction.ExtendedData[
                        "SI_CURRENT_AUTOGIVER"
                    ]
                    delete state.transaction.ExtendedData["AG_CONTINUE_CURRENT"]
                    break
                case "monthly":
                    state.transaction.RecurrenceCap = 0
                    delete state.transaction.ExtendedData[
                        "SI_CURRENT_AUTOGIVER"
                    ]
                    delete state.transaction.ExtendedData["AG_CONTINUE_CURRENT"]
                    break
                case "increaseMonthly":
                    state.transaction.RecurrenceCap = 0
                    state.transaction.ExtendedData["SI_CURRENT_AUTOGIVER"] =
                        true
                    state.transaction.ExtendedData["AG_CONTINUE_CURRENT"] =
                        false
            }
        },
        updatePremiumSelection(state, { value, code }) {
            state.isPremiumSelected = value
            if (state.isPremiumSelected) {
                var premium = {
                    Code: code,
                    Quantity: 1,
                    HasBeenPushed: false,
                }

                state.transaction.Premiums[0] = premium
            } else {
                state.transaction.Premiums.splice(0, 1)
            }
        },
    },
    actions: {
        $touch() {
            vuelidator.$v.$touch()
        },
        async createOrUpdatePaymentIntent(context, request) {
            const { data, token } = request
            try {
                const paymentIntentResponse = await httpClient.post(
                    `/api/submit/createorupdatepaymentintent?token=${token}`,
                    data,
                )
                context.commit("setPaymentIntent", paymentIntentResponse)
                return paymentIntentResponse.data
            } catch (error) {
                if (
                    error.response.status == 400 &&
                    Object.keys(error.response.data.errors ?? {}).includes(
                        "invalid_token",
                    )
                ) {
                    throw new RecaptchaError(error.response.data.traceId)
                }
                throw error
            }
        },
        async getOrCreateCustomer(context, request) {
            const { data, token } = request
            try {
                const response = await httpClient.post(
                    `/api/submit/getOrCreateCustomer?token=${token}`,
                    data,
                )
                context.commit("setCustomer", response)
                return response.data
            } catch (error) {
                if (
                    error.response.status == 400 &&
                    Object.keys(error.response.data.errors ?? {}).includes(
                        "invalid_token",
                    )
                ) {
                    throw new RecaptchaError(error.response.data.traceId)
                }
                throw error
            }
        },
        getDdrpStationsForZip: (context, data) => {
            return httpClient.get(
                "/api/code/getzipstations/" + data.zip + "/" + data.designation,
            )
        },
        getDropdownData: (context) => {
            const getCountries = httpClient.get("/api/address/getcountries")
            const getStates = httpClient.get("/api/address/getstates")
            const getProvinces = httpClient.get("/api/address/getprovs")
            return Promise.all([getCountries, getStates, getProvinces]).then(
                (responses) => {
                    context.commit("loadCountries", responses[0].data)
                    context.commit("loadStates", responses[1].data)
                    context.commit("loadProvinces", responses[2].data)
                },
            )
        },
        getRecaptchaToken: (context, action) => {
            // eslint-disable-line no-unused-vars
            return new Promise((resolve) => {
                grecaptcha
                    .execute(import.meta.env.VITE_RE_SITE_KEY, { action })
                    .then((token) => resolve(token))
            })
        },
        getRecipe: (context, { id, preview, premium }) => {
            // Regex to determine if we should fetch the form by id or by alias
            var regex = /^\d+$/
            var path = "/api/recipe/"
            // Get by alias unless the supplied identifier is all digits
            if (regex.test(id)) {
                path += `getbyid/${id}`
                if (preview) {
                    //set adminView for previewed forms
                    context.commit("setAdminView")
                    if (premium) path += `/${premium}`
                }
            } else path += id

            return new Promise((resolve, reject) => {
                httpClient
                    .get(path)
                    .then((resp) => {
                        context.commit("loadRecipe", resp.data)
                        resolve()
                    })
                    .catch((error) => {
                        if (!error.response || error.response.status !== 404) {
                            console.log("error", error)
                            Vue.rollbar.error(error)
                            // todo - log rollbar error for forms that don't resolve but aren't true 404s
                        }
                        context.dispatch("redirectTo404")
                        // Even though we are redirecting, reject the promise to cut short any other page load activity
                        reject()
                    })
            })
        },
        async getStripeSetupIntent(context, request) {
            const { data, token } = request
            try {
                const setupIntentResponse = await httpClient.post(
                    `api/submit/setupintent?token=${token}`,
                    data,
                )
                context.commit("setStripeSetupIntent", setupIntentResponse)
                return setupIntentResponse.data
            } catch (error) {
                if (
                    error.response.status == 400 &&
                    Object.keys(error.response.data.errors ?? {}).includes(
                        "invalid_token",
                    )
                ) {
                    throw new RecaptchaError(error.response.data.traceId)
                }
                throw error
            }
        },
        getZipData: (context, zip) => {
            context.state.searchingZip = true
            return new Promise((resolve, reject) => {
                httpClient
                    .get("/api/address/zip/" + zip)
                    .then((resp) => {
                        context.state.searchingZip = false
                        context.commit("loadZipData", resp.data)
                        resolve(resp)
                    })
                    .catch((error) => {
                        context.state.searchingZip = false

                        // Log error with rollbar (may not be available in all non-prod instances)
                        if (Vue.rollbar)
                            Vue.rollbar.error("Error performing zip lookup")
                        reject(error)
                    })
            })
        },
        recaptchaEnabled(context) {
            var enabledPromise = httpClient.get("/api/recaptcha")
            enabledPromise.then((r) => {
                context.state.recaptchaEnabled = r.data === true

                if (context.state.recaptchaEnabled) {
                    var els =
                        document.getElementsByClassName("grecaptcha-badge")
                    if (els && els.length > 0) els[0].className += " revealed"
                }
            })
            return enabledPromise
        },
        // If a custom 404 page is ever desire this method could trigger a router.push
        // For now, let's route to a bogus path that falls back to an epi error page
        redirectTo404() {
            window.location.replace(
                window.location.protocol +
                    "//" +
                    window.location.hostname +
                    "/404",
            )
        },
        redirectToErrorPage(context, reason) {
            router.push({
                name: "error",
                params: {
                    id: context.state.form.id,
                    reason: reason,
                    originalPath: router.history.current.fullPath,
                },
            })
        },
        resetVuelidation() {
            vuelidator.$v.$reset()
        },
        async submitError(context, data) {
            const response = await httpClient.post(
                "/api/submit/submiterror",
                data,
            )
            const statusCode = response.data.statusCode
            // pick the first one that matches the key.
            const localizedText = context.state.text[this.getters.formLanguage]
            var relevantMessage = localizedText.submitErrorMessages.find(
                (msg) => msg.Key.some((k) => k == statusCode),
            )
            localizedText.submitErrorText =
                relevantMessage?.Value ?? localizedText.submitErrorTextDefault
            if (statusCode == 1) {
                const tenderType = context.state.transaction.Payment.TenderType
                let additionalMessage =
                    tenderType == "ECHK"
                        ? localizedText.submitErrorTextAch
                        : localizedText.submitErrorTextCreditCard
                localizedText.submitErrorText += additionalMessage
            }
            localizedText.submitErrorText += `
                <p>${localizedText.submitErrorContactText}</p>
                <p>(${
                    localizedText.submit.errorCode
                }: ${statusCode}-${TraceContext.getRootContext().traceId.substring(
                    0,
                    4,
                )})</p>
            `
            return {
                statusCode: response.data.statusCode,
                userMessage: localizedText.submitErrorText,
            }
        },
        async postDfTransaction(context) {
            try {
                /*
                if (!context.state.recaptchaEnabled) {
                    // If recaptcha was disabled make sure this is still the case before trying to submit without it
                    await context.dispatch('recaptchaEnabled');
                }

                if (context.state.recaptchaEnabled) {
                    try {
                        var token = await context.dispatch('getRecaptchaToken', 'givingpage_submit');
                        context.state.transaction.Token = token;
                    } catch (error) {
                        if (!window.location.host.includes('msdvap') && !window.location.host.includes('msteap')) {
                            throw error.message;
                        }
                    }
                }
                */

                // Set aside receipt data that is needed for the confirmation page
                // This data won't be displayed unless the charge is successful, but we should set it aside now to avoid manipulation of the submission during the request.
                context.state.receiptData = {
                    acctLastFour:
                        context.state.transaction.Payment.AcctLastFour,
                    Amount: context.state.transaction.Splits[0].Amount.toFixed(
                        2,
                    ),
                    campaign: context.state.transaction.Campaign,
                    // Copy the donor object so that the country field can be manipulated without impacting the post object
                    Donor: { ...context.state.transaction.Donor },
                    giftDate: new Date().toLocaleDateString(
                        context.getters.formLanguage == "spanish"
                            ? "es-419"
                            : "en-US",
                        { year: "numeric", month: "long", day: "numeric" },
                    ),
                    giftType: context.state.giftType,
                    RecurrenceCap: context.state.transaction.RecurrenceCap,
                    TenderType: context.state.transaction.Payment.TenderType,
                }
                context.state.receiptData.Donor.Country =
                    context.state.countryList.length > 0
                        ? context.state.countryList.find(
                              (country) =>
                                  country.code ==
                                  context.state.transaction.Donor.Country,
                          )
                        : {
                              code: "US",
                              label: "United States",
                              longCode: "USA",
                          }
            } catch (error) {
                console.error(
                    "Submit error caught before donor flow post attempt:",
                    error,
                )
                throw error
            }

            return httpClient
                .post("/api/submit/", context.state.transaction)
                .then((resp) => {
                    const txId = resp.data.txId
                    context.commit("updateTransaction", {
                        propLocation: ["TxId"],
                        value: txId,
                    })

                    // Set aside receipt data and other form values that are needed for the confirmation page
                    var recipe = context.state.recipe
                    var recaptchaEnabled = context.state.recaptchaEnabled
                    var form = context.state.form
                    var receipt = context.state.receiptData
                    receipt.TxId = txId

                    // Clear the form by resetting the vuex state, re-populating it only with the recipe and receipt, and resetting validation
                    context.commit("resetState")
                    context.state.recipe = recipe
                    context.state.receiptData = receipt
                    context.commit("setReceipt", receipt)
                    context.state.recaptchaEnabled = recaptchaEnabled
                    context.state.form = form
                    context.dispatch("resetVuelidation")
                })
                .catch((error) => {
                    // Log recaptcha rejections
                    if (
                        import.meta.env.MODE === "production" &&
                        error.response.status === 400 &&
                        Vue.rollbar
                    ) {
                        Vue.rollbar.warning(
                            "Gift submission rejected by recaptcha (" +
                                error.response.data +
                                ")",
                        )
                    }
                    // Log all other exceptions
                    else if (import.meta.env.MODE === "production") {
                        Vue.rollbar.error("Error submitting gift", error)
                    }
                    console.error(
                        "Submit error caught during donor flow post attempt:",
                        error,
                    )
                })
        },
        async validateRecaptcha(context, request) {
            try {
                const resp = await httpClient.post(
                    "/api/submit/validate",
                    request,
                )
                if (resp.status == 204) {
                    context.commit("setValidRecaptcha", true)
                } else {
                    context.state.text[
                        context.getters.formLanguage
                    ].submitErrorText =
                        context.state.text[
                            context.getters.formLanguage
                        ].submitErrorTextDefault
                    context.state.submitError = true
                }
            } catch {
                context.state.text[
                    context.getters.formLanguage
                ].submitErrorText =
                    context.state.text[
                        context.getters.formLanguage
                    ].submitErrorTextDefault
                context.state.submitError = true
            }
        },
        async verifyToken(context, request) {
            try {
                await httpClient.post("/api/recaptcha/validate", request)
            } catch (error) {
                // Redirect questionable page loads
                if (error.response.status === 400) {
                    context.dispatch(
                        "redirectToErrorPage",
                        "Page load rejected by recaptcha (" +
                            error.response.data +
                            ")",
                    )
                }

                console.log(error.response)
            }
        },
    },
})

export default store
