<template>
    <div v-if="text">
        <headingRow :text="text.address.contactHeader" size="17px" />

        <div class="row">
            <div class="col-md-6">
                <label for="firstName">{{
                    text.address.fieldHeaders.firstName
                }}</label>
                <input
                    id="firstName"
                    v-model="firstName"
                    type="text"
                    class="form-control"
                    @blur="setTouched('firstName')" />
                <p
                    v-if="$v.firstName.$dirty && $v.firstName.$invalid"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
            <div class="col-md-6">
                <label for="lastName">{{
                    text.address.fieldHeaders.lastName
                }}</label>
                <input
                    id="lastName"
                    v-model="lastName"
                    type="text"
                    class="form-control"
                    @blur="setTouched('lastName')" />
                <p
                    v-if="$v.lastName.$dirty && $v.lastName.$invalid"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
        </div>
        <label for="isOrgGift" style="margin-bottom: 20px; margin-left: 7px"
            ><input id="isOrgGift" v-model="isOrgGift" type="checkbox" />{{
                text.address.orgGiftCheckbox
            }}</label
        >
        <div v-if="isOrgGift" class="row">
            <div class="col-md-12">
                <label for="address1">{{
                    text.address.fieldHeaders.companyName
                }}</label>
                <input
                    id="organization"
                    v-model="organization"
                    type="text"
                    class="form-control"
                    @blur="setTouched('organization')" />
                <p
                    v-if="$v.organization.$dirty && $v.organization.$invalid"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="country">{{
                    text.address.fieldHeaders.country
                }}</label>
                <v-select
                    v-model="country"
                    class="select"
                    :options="countryList"
                    :clearable="false"
                    :disabled="
                        dropdownOptionsUnavailable || !dropdownFetchFinished
                    "></v-select>
                <!--v-if="countryList != null"-->
            </div>
            <div class="col-md-12">
                <p
                    v-if="dropdownOptionsUnavailable"
                    class="text-muted placeholder">
                    International donations are temporarily unavailable.
                </p>
                <p
                    v-else-if="
                        premiumUnavailable &&
                        (premiumLocation != 'bottom' ||
                            (premiumLocation == 'bottom' && premiumWasSelected))
                    "
                    class="text-muted">
                    {{ text.validation.intlPremiumUnavailable }}
                </p>
                <p v-else class="text-muted placeholder">{{ "" }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="address1">{{
                    text.address.fieldHeaders.address1
                }}</label>
                <input
                    id="address1"
                    v-model="address1"
                    type="text"
                    class="form-control"
                    @blur="setTouched('address1')" />
                <p
                    v-if="$v.address1.$dirty && $v.address1.$invalid"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
        </div>
        <div v-if="showAddress2" class="row">
            <div class="col-md-12">
                <label for="address2">{{
                    text.address.fieldHeaders.address2
                }}</label>
                <input
                    id="address2"
                    v-model="address2"
                    type="text"
                    class="form-control" />
                <p class="error-text placeholder"></p>
            </div>
        </div>
        <div class="row">
            <transition v-if="showCityAndState" name="fade">
                <div class="col-md-4">
                    <label for="city">{{
                        text.address.fieldHeaders.city
                    }}</label>
                    <input
                        id="city"
                        v-model="city"
                        type="text"
                        class="form-control"
                        :disabled="
                            country &&
                            country.code == 'US' &&
                            !addressServiceUnavailable
                        "
                        @blur="setTouched('city')" />
                    <p
                        v-if="$v.city.$dirty && $v.city.$invalid"
                        class="error-text text-right">
                        {{ text.validation.fieldRequired }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </transition>
            <transition v-if="showCityAndState" name="fade">
                <div class="col-md-5">
                    <label for="state">{{ stateLabel }}</label>
                    <input
                        v-if="
                            country == null ||
                            (country &&
                                country.code != 'US' &&
                                country.code != 'CA')
                        "
                        id="state"
                        v-model="state"
                        type="text"
                        class="form-control"
                        @blur="setTouched('state')" />
                    <input
                        v-if="
                            country &&
                            country.code == 'US' &&
                            !addressServiceUnavailable
                        "
                        id="state"
                        v-model="stateDescription"
                        type="text"
                        class="form-control"
                        disabled
                        @blur="setTouched('state')" />
                    <v-select
                        v-if="
                            stateList != null &&
                            country &&
                            country.code == 'US' &&
                            addressServiceUnavailable
                        "
                        v-model="state"
                        class="select"
                        :options="stateList"
                        :reduce="(state) => state.code"
                        :clearable="false"></v-select>
                    <v-select
                        v-if="
                            provinceList != null &&
                            country &&
                            country.code == 'CA'
                        "
                        v-model="state"
                        class="select"
                        :options="provinceList"
                        :reduce="(state) => state.code"
                        :clearable="false"></v-select>
                    <p
                        v-if="$v.state.$dirty && $v.state.$invalid"
                        class="error-text text-right">
                        {{ text.validation.fieldRequired }}
                    </p>
                    <p v-else class="error-text placeholder"></p>
                </div>
            </transition>
            <div class="col-md-3">
                <label for="zip">
                    {{ zipLabel }}
                </label>
                <span v-if="searchingZip" id="spinnySpan">
                    <clip-loader
                        :loading="true"
                        :color="recipe.design.theme.accentColor"
                        size="17px"
                        style="display: inline-block; padding-left: 10px">
                    </clip-loader>
                </span>
                <input
                    v-if="!country || useUsPostalFormat"
                    id="zip"
                    v-model="zip"
                    v-mask="'#####'"
                    type="text"
                    class="form-control"
                    @blur="setTouched('postal')" />
                <input
                    v-if="country.code == 'CA'"
                    id="zip"
                    v-model="zip"
                    v-mask="'A#A #A#'"
                    type="text"
                    class="form-control"
                    @blur="setTouched('postal')" />
                <input
                    v-if="country && !useUsPostalFormat && country.code != 'CA'"
                    id="zip"
                    v-model="zip"
                    type="text"
                    class="form-control"
                    @blur="setTouched('postal')" />
                <p v-if="badZipEntered" class="error-text text-right">
                    Zip code is invalid
                </p>
                <p
                    v-else-if="$v.postal.$dirty && !$v.postal.required"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p
                    v-else-if="
                        $v.postal.$dirty &&
                        $v.postal.$error &&
                        country.code != 'CA'
                    "
                    class="error-text text-right">
                    {{ text.validation.zipLength }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="phone">{{ text.address.fieldHeaders.phone }}</label>
                <input
                    v-if="!country || useUsPhoneFormat"
                    id="phone"
                    v-model="phoneMasked"
                    v-mask="phoneMask"
                    type="text"
                    class="form-control"
                    @blur="setTouched('phone')" />
                <input
                    v-if="country && !useUsPhoneFormat"
                    id="phone"
                    v-model="phone"
                    type="text"
                    class="form-control"
                    @blur="setTouched('phone')" />
                <p
                    v-if="
                        $v.phone.$dirty &&
                        !$v.phone.required &&
                        $v.phone.$error &&
                        ($v.phone.$model === 'null' || $v.phone.$model === '')
                    "
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p
                    v-else-if="$v.phone.$dirty && $v.phone.$invalid"
                    class="error-text text-right">
                    {{ text.validation.phone }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
            <div class="col-md-6">
                <label for="email">{{ text.address.fieldHeaders.email }}</label>
                <input
                    id="email"
                    v-model="email"
                    type="text"
                    class="form-control"
                    @blur="setTouched('email')" />
                <p
                    v-if="$v.email.$dirty && !$v.email.required"
                    class="error-text text-right">
                    {{ text.validation.fieldRequired }}
                </p>
                <p
                    v-else-if="$v.email.$dirty && !$v.email.email"
                    class="error-text text-right">
                    {{ text.validation.email }}
                </p>
                <p v-else class="error-text placeholder"></p>
            </div>
        </div>
        <ddrpStation v-if="recipe.features.ddrpStationSelect"></ddrpStation>
    </div>
</template>
<script>
import headingRow from "../text/HeadingRow.vue"
import ddrpStation from "./DdrpStation.vue"
import clipLoader from "vue-spinner/src/ClipLoader.vue"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
    name: "AddressSection",
    components: { headingRow, clipLoader, vSelect, ddrpStation },
    props: {
        enableZipLookup: Boolean,
    },
    data: function () {
        return {
            badZipEntered: false,
            cityAndStateNowVisible: false,
            phoneMask: [
                "(",
                /[2-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            phoneMasked: null,
            showAddress2: false,
            zipLookupFailure: false,
            premiumWasSelected: false,
        }
    },
    computed: {
        address1: {
            get() {
                return this.transaction.Donor.Address1
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Address1"],
                    value: value,
                })
            },
        },
        address2: {
            get() {
                return this.transaction.Donor.Address2
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Address2"],
                    value: value,
                })
            },
        },
        addressServiceUnavailable: {
            get() {
                return this.zipLookupFailure ? true : false // this.dropdownOptionsUnavailable ||
            },
        },
        city: {
            get() {
                return this.transaction.Donor.City
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "City"],
                    value: value,
                })
            },
        },
        country: {
            get() {
                return this.dropdownFetchFinished &&
                    !this.dropdownOptionsUnavailable &&
                    this.transaction.Donor.Country != null
                    ? this.countryList.find(
                          (country) =>
                              country.code == this.transaction.Donor.Country,
                      )
                    : { code: "US", label: "United States", longCode: "USA" }
            },
            set(value) {
                var cleanValue = value == null ? null : value.code
                if (
                    this.transaction.Donor.Country == "US" ||
                    this.transaction.Donor.Country == "CA" ||
                    cleanValue == "US" ||
                    cleanValue == "CA"
                ) {
                    this.address1 = null
                    this.address2 = null
                    this.city = null
                    this.state = null
                    this.zip = null
                }
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Country"],
                    value: cleanValue,
                })
                this.cityAndStateNowVisible = cleanValue == "US" ? false : true
                this.showAddress2 =
                    cleanValue == "US" && !this.address2 ? false : true
            },
        },
        countryList: {
            get() {
                return this.$store.state.countryList &&
                    this.$store.state.countryList.length > 0
                    ? this.$store.state.countryList
                    : []
            },
        },
        dropdownOptionsUnavailable: {
            get() {
                return this.dropdownFetchFinished &&
                    (this.countryList.length == 0 ||
                        this.stateList.length == 0 ||
                        this.provinceList.length == 0)
                    ? true
                    : false
            },
        },
        dropdownFetchFinished: {
            get() {
                return (
                    this.$store.state.isCountriesLoaded &&
                    this.$store.state.isStatesLoaded &&
                    this.$store.state.isProvincesLoaded
                )
            },
        },
        email: {
            get() {
                return this.transaction.Donor.Email
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Email"],
                    value: value,
                })
            },
        },
        firstName: {
            get() {
                return this.transaction.Donor.FirstName
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "FirstName"],
                    value: value,
                })
            },
        },
        isOrgGift: {
            get() {
                return this.$store.state.isOrgGift
            },
            set(value) {
                if (!value) {
                    this.organization = null
                }
                if (value) {
                    this.showAddress2 = true
                }
                this.$store.state.isOrgGift = value
            },
        },
        isPremiumSelected: {
            get() {
                return this.$store.state.isPremiumSelected
            },
        },
        lastName: {
            get() {
                return this.transaction.Donor.LastName
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "LastName"],
                    value: value,
                })
            },
        },
        organization: {
            get() {
                return this.transaction.Donor.Organization
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Organization"],
                    value: value,
                })
            },
        },
        phone: {
            get() {
                return this.transaction.Donor.Phone
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Phone"],
                    value: value,
                })
            },
        },
        provinceList: {
            get() {
                return this.$store.state.provinceList &&
                    this.$store.state.provinceList.length > 0
                    ? this.$store.state.provinceList
                    : []
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        premium: {
            get() {
                return this.$store.getters.premium
            },
        },
        premiumLocation: {
            get() {
                return this.premium ? this.premium.layout.location : null
            },
        },
        premiumUnavailable: {
            get() {
                return this.$store.state.premiumUnavailable
            },
        },
        searchingZip: {
            get() {
                return this.$store.state.searchingZip
            },
        },
        showCityAndState: {
            get() {
                return (
                    !this.enableZipLookup ||
                    this.cityAndStateNowVisible ||
                    this.addressServiceUnavailable
                )
            },
        },
        state: {
            get() {
                return this.transaction.Donor.State
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "State"],
                    value: value,
                })
            },
        },
        stateDescription: {
            get() {
                var stateObj =
                    this.state &&
                    this.stateList.find((state) => state.code == this.state)
                if (stateObj) return stateObj.label

                return null
            },
        },
        stateLabel: {
            get() {
                // No countries loaded yet, or US selected
                if (!this.country || this.country.code == "US")
                    return this.text.address.fieldHeaders.state
                // Everybody else
                return this.country.code == "CA"
                    ? this.text.address.fieldHeaders.province
                    : this.text.address.fieldHeaders.stateProvince
            },
        },
        stateList: {
            get() {
                return this.$store.state.stateList &&
                    this.$store.state.stateList.length > 0
                    ? this.$store.state.stateList
                    : this.$store.state.text[this.$store.getters.formLanguage]
                          .defaultStateList
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
        transaction: {
            get() {
                return this.$store.state.transaction
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
        zip: {
            get() {
                return this.transaction.Donor.Postal
            },
            set(value) {
                if (value === this.zip) return

                this.$store.commit("updateTransaction", {
                    propLocation: ["Donor", "Postal"],
                    value: value,
                })

                if (value) {
                    if (this.transaction.Donor.Country == "US") {
                        if (
                            this.enableZipLookup &&
                            !this.$v.postal.$invalid &&
                            !this.addressServiceUnavailable
                        ) {
                            this.$store
                                .dispatch("getZipData", value)
                                .then((resp) => {
                                    if (resp.status == 204) {
                                        this.city = null
                                        this.state = null
                                        this.badZipEntered = true
                                    } else {
                                        // Set flag so that the city state are visible from now on, even if zip is cleared
                                        this.cityAndStateNowVisible = true
                                        this.badZipEntered = false
                                    }
                                })
                                .catch((error) => {
                                    this.zipLookupFailure = true
                                    this.badZipEntered = false
                                    this.cityAndStateNowVisible = true

                                    console.log("error", error)
                                })
                        } else if (
                            this.enableZipLookup &&
                            this.$store.state.zipData
                        ) {
                            // Clear zip data as soon as it becomes outdated
                            // (though we'll leave city and state in case the user has begun to update them manually)
                            this.$store.commit("resetZipData", value)
                        }
                    } else if (this.transaction.Donor.Country == "CA") {
                        this.badZipEntered =
                            this.$v.postal.$dirty && this.$v.postal.$error
                    }
                }
            },
        },
        useUsPhoneFormat() {
            return this.country ? this.country.nanpParticipant === true : false
        },
        useUsPostalFormat() {
            return this.country
                ? this.country.usStyleZipCodeParticipant === true
                : false
        },
        zipLabel: {
            get() {
                if (this.$store.getters.formLanguage == "english") {
                    return !this.country || this.useUsPostalFormat
                        ? this.text.address.fieldHeaders.zip
                        : this.text.address.fieldHeaders.postal
                } else {
                    return this.text.address.fieldHeaders.postal
                }
            },
        },
    },
    watch: {
        country: function (value) {
            if (this.premium && value) {
                if (value.code != "US" && value.code != "CA") {
                    this.$store.commit("updatePremiumUnavailable", true)
                } else if (
                    this.premiumUnavailable &&
                    (value.code == "US" || value.code == "CA")
                ) {
                    this.$store.commit("updatePremiumUnavailable", false)
                }
            }
        },
        isPremiumSelected: function (value) {
            if (value) this.premiumWasSelected = true
        },
        phoneMasked: function () {
            this.phone = this.phoneMasked.replace(/\D/g, "")
        },
        showAddress2: function (value) {
            if (value === false) {
                this.address2 = null
            }
        },
    },
    created: function () {
        this.$store.dispatch("getDropdownData")
    },
    methods: {
        //getErrorText: function (field) {
        //    return this.$v[field].$invalid ? 'This field is required' : null;
        //},
        setTouched: function (field) {
            this.$v[field].$touch()
        },
    },
}
</script>
<style scoped>
#spinnySpan {
    position: relative;
    top: 3px;
}

:deep(.fade-enter-active),
:deep(.fade-leave-active) {
    transition: opacity 0.5s;
}

:deep(fade-enter), :deep(.fade-leave-to) /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

:deep(.v-select) {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
}

:deep(.vs__selected-options),
:deep(.vs__actions) {
    margin-top: 0.2rem;
}

:deep(.vs__dropdown-toggle) {
    padding-bottom: 0.3rem;
}

/*.vs--open {
        height:38.5px;
    }*/

input[type="checkbox"] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -4px;
    right: 4px;
    overflow: hidden;
}

input::placeholder {
    color: lightgray;
}

input:disabled {
    background: none;
    border-color: darkgray;
    cursor: not-allowed;
}

.text-muted {
    font-size: 12px;
}
</style>
