import Vue from "vue"
import { validationMixin } from "vuelidate"
import {
    required,
    decimal,
    email,
    minLength,
    maxLength,
    minValue,
    numeric,
} from "vuelidate/lib/validators"

export function init(store) {
    const validRouting = function (t) {
        if (t == null || t.length !== 9) return false

        var n = 0
        for (var i = 0; i < t.length; i += 3) {
            n +=
                parseInt(t.charAt(i), 10) * 3 +
                parseInt(t.charAt(i + 1), 10) * 7 +
                parseInt(t.charAt(i + 2), 10)
        }

        // If the resulting sum is an even multiple of ten (but not zero),
        // the aba routing number is good.
        if (n != 0 && n % 10 == 0) return true
        else return false
    }

    const routing = (number) => validRouting(number)
    const stripeRequired = (element) => {
        return !element.empty
    }
    const stripeComplete = (element) => {
        return element.complete
    }

    return new Vue({
        mixins: [validationMixin],
        data: function () {
            return {
                global: {
                    address1: { required, minLength: minLength(3) },
                    amount: {
                        required,
                        decimal,
                        minValue: minValue(
                            Number(import.meta.env.VITE_MIN_AMOUNT),
                        ),
                    },
                    city: { required },
                    email: { required, email },
                    firstName: { required },
                    giftType: { required },
                    lastName: { required },
                    payMethod: { required },
                    state: { required },
                    tenderType: { required },
                    phone: {
                        numeric,
                        minLength: minLength(10),
                        maxLength: maxLength(15),
                    },
                    postal: { required },
                },
                usOnly: {
                    phone: {
                        required,
                        numeric,
                        minLength: minLength(10),
                        maxLength: maxLength(10),
                    },
                    postal: {
                        required,
                        numeric,
                        minLength: minLength(5),
                        maxLength: maxLength(5),
                    },
                },
                caOnly: {
                    phone: {
                        required,
                        numeric,
                        minLength: minLength(10),
                        maxLength: maxLength(10),
                    },
                    postal: {
                        required,
                        minLength: minLength(7),
                        maxLength: maxLength(7),
                    },
                },
                nonUs: {
                    phone: {
                        numeric,
                        minLength: minLength(10),
                        maxLength: maxLength(15),
                    },
                    postal: { required },
                },
                OLP: {
                    stripeCardNumber: { stripeRequired, stripeComplete },
                    stripeCardExpiry: { stripeRequired, stripeComplete },
                    stripeCardCvc: { stripeRequired, stripeComplete },
                },
                ECHK: {
                    accountNumber: {
                        required,
                        numeric,
                        minLength: minLength(5),
                        maxLength: maxLength(17),
                    },
                    routingNumber: {
                        required,
                        numeric,
                        minLength: minLength(9),
                        maxLength: maxLength(9),
                        routing,
                    },
                    bankAccountType: { required, numeric },
                },
                Org: {
                    organization: { required },
                },
                ddrp: {
                    station: { required, minLength: minLength(1) },
                },
                nanpParticipant: {
                    phone: {
                        numeric,
                        minLength: minLength(10),
                        maxLength: maxLength(10),
                    },
                },
                usStyleZipCodeParticipant: {
                    postal: {
                        required,
                        numeric,
                        minLength: minLength(5),
                        maxLength: maxLength(5),
                    },
                },
            }
        },
        computed: {
            accountNumber() {
                return store.state.transaction.AccountNumber
            },
            address1() {
                return store.state.transaction.Donor.Address1
            },
            amount() {
                return store.state.transaction.Splits[0].Amount
            },
            bankAccountType() {
                return store.state.transaction.Payment.EftAccountType
            },
            city() {
                return store.state.transaction.Donor.City
            },
            country() {
                return store.state.transaction.Donor.Country
            },
            countryData() {
                return this.dropdownFetchFinished && this.country != null
                    ? this.countryList.find(
                          (country) =>
                              country.code ==
                              store.state.transaction.Donor.Country,
                      )
                    : { code: "US", label: "United States", longCode: "USA" }
            },
            countryList() {
                return store.state.countryList &&
                    store.state.countryList.length > 0
                    ? store.state.countryList
                    : []
            },
            dropdownFetchFinished() {
                return (
                    store.state.isCountriesLoaded &&
                    store.state.isStatesLoaded &&
                    store.state.isProvincesLoaded
                )
            },
            isDdrp() {
                return store.state.recipe.features.ddrpStationSelect
            },
            email() {
                return store.state.transaction.Donor.Email
            },
            firstName() {
                return store.state.transaction.Donor.FirstName
            },
            giftType() {
                return store.state.giftType
            },
            isOrgGift() {
                return store.state.isOrgGift
            },
            lastName() {
                return store.state.transaction.Donor.LastName
            },
            useUsPhoneFormat() {
                return this.countryData
                    ? this.countryData.nanpParticipant === true
                    : false
            },
            organization() {
                return store.state.transaction.Donor.Organization
            },
            payMethod() {
                return store.state.transaction.Payment.TenderType
            },
            phone() {
                return store.state.transaction.Donor.Phone
            },
            postal() {
                return store.state.transaction.Donor.Postal
            },
            routingNumber() {
                return store.state.transaction.Payment.RoutingNumber
            },
            state() {
                return store.state.transaction.Donor.State
            },
            station() {
                return store.state.transaction.DDGI.Frequency
            },
            stripeCardNumber() {
                return store.state.stripeCardNumberEvent
            },
            stripeCardExpiry() {
                return store.state.stripeCardExpiryEvent
            },
            stripeCardCvc() {
                return store.state.stripeCardCvcEvent
            },
            tenderType() {
                return store.state.transaction.Payment.TenderType
            },
            useUsPostalFormat() {
                return this.countryData
                    ? this.countryData.usStyleZipCodeParticipant === true
                    : false
            },
        },
        store,
        validations: function () {
            var rules = Object.assign({}, this.global)
            if (this.country === "US") {
                rules = Object.assign(rules, this.usOnly)
            } else if (this.country === "CA") {
                rules = Object.assign(rules, this.caOnly)
            } else if (this.useUsPhoneFormat || this.useUsPostalFormat) {
                if (this.useUsPhoneFormat && this.useUsPostalFormat) {
                    rules = Object.assign(
                        rules,
                        this.nanpParticipant,
                        this.usStyleZipCodeParticipant,
                    )
                } else if (this.useUsPhoneFormat) {
                    rules = Object.assign(rules, this.nanpParticipant)
                } else if (this.useUsPostalFormat) {
                    rules = Object.assign(rules, this.usStyleZipCodeParticipant)
                } else {
                    rules = Object.assign(rules, this.nonUs)
                }
            } else {
                rules = Object.assign(rules, this.nonUs)
            }

            if (this.tenderType) {
                rules = Object.assign(rules, this[this.tenderType])
            }
            if (this.isOrgGift) {
                rules = Object.assign(rules, this.Org)
            }

            if (this.isDdrp) {
                rules = Object.assign(rules, this.ddrp)
            }

            return rules
        },
    })
}
