<template>
    <div class="text-muted" style="font-size: small">
        <p class="text-center">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-show="!hideContact && problemsText" v-html="problemsText" />
            <br />Moody Bible Institute © {{ new Date().getFullYear() }}.
            {{ footerText.rightsReserved }}
        </p>

        <p class="text-center">
            <img
                class="my-2"
                src="https://moodybible.canto.com/direct/image/6gpjft3v0h1rralpp8ra4rcs54/4oUlkOg7mDo2JojTAZ2jsncVErA/original?content-type=image%2Fpng&name=ecfa-seal-bw.png"
                alt="ECFA Member" />
        </p>

        <!-- eslint-disable vue/no-v-html -->
        <p
            class="recaptchaFooter text-center"
            :class="{ revealed: recaptchaEnabled }"
            style="padding-top: 1em"
            v-html="footerText.recaptchaPolicy"></p>
        <!-- eslint-enable vue/no-v-html -->
    </div>
</template>
<script>
export default {
    name: "FooterSection",
    props: {
        hideContact: Boolean,
    },
    computed: {
        footerText: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                          .footer
                    : null
            },
        },
        params: {
            get() {
                return (
                    "?" +
                    this.$route.fullPath.split("?")[1] +
                    "&form=" +
                    this.$store.state.form.id
                )
            },
        },
        problemsText: {
            get() {
                return this.footerText
                    ? this.footerText.problemsText.replace(
                          "{PARAMS}",
                          this.params,
                      )
                    : null
            },
        },
        recaptchaEnabled: {
            get() {
                return this.$store.state.recaptchaEnabled
            },
        },
    },
}
</script>
<style>
/*style block needs to be unscoped in order for the grecaptcha-badge rules to work*/
.recaptchaFooter:not(.revealed) {
    display: none;
}

@media print {
    .recaptchaFooter.revealed {
        display: none;
    }
}

@media (min-width: 768px) {
    .recaptchaFooter.revealed {
        display: none;
    }
    .grecaptcha-badge.revealed {
        visibility: visible;
    }
}

@media (max-width: 767px) {
    .recaptchaFooter.revealed {
        margin-top: 1rem;
        display: normal;
    }
    .grecaptcha-badge.revealed {
        visibility: hidden;
    }
}
</style>
