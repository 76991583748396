<template>
    <div v-if="conditionsText" id="terms-blurb">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="" v-html="conditionsText"></div>
        <br />
    </div>
</template>
<script>
export default {
    name: "TermsConditionsBlurb",
    computed: {
        cardType: {
            get() {
                return this.$store.getters.getCardType(true)
                    ? this.$store.getters.getCardType(true)
                    : ""
            },
        },
        chargeDate: {
            get() {
                // to test last day of month, uncomment these three lines and comment out .getDate() immediately afterward
                //const today = new Date()
                //const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                //var date = lastDayOfMonth.getDate();

                var date = new Date().getDate()
                if (date > 28) return this.termsText.lastDay
                if (date < 10 && date == 1) return date + "st"
                if (date < 10 && date == 2) return date + "nd"
                if (date < 10 && date == 3) return date + "rd"
                return date + "th"
            },
        },
        chargePhrase: {
            get() {
                // spanish version of "chargeDate"
                const today = new Date()

                // to test last day of month, uncomment this and replace today.getDate() with lastDayOfMonth.getDate() below
                //const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
                //var date = lastDayOfMonth.getDate();

                var date = today.getDate()
                if (date > 28) return this.termsText.lastDay
                else return this.termsText.day.replace("{0}", date)
            },
        },
        conditionsText: {
            get() {
                if (
                    this.giftType == null ||
                    this.transaction.Splits[0].Amount == null ||
                    this.transaction.Payment.TenderType == null
                ) {
                    return null
                }
                var text = ""
                if (this.giftType == "single") {
                    text =
                        "<p>" +
                        this.termsText.introSingle.replace(
                            "{AMOUNT}",
                            this.transaction.Splits[0].Amount,
                        ) +
                        "</p>"
                } else {
                    text = "<p>" + this.termsText.introAuto + "</p>"
                }

                if (this.transaction.Payment.TenderType == "ECHK") {
                    text += "<p>" + this.termsText.authUserEchk + " "

                    if (this.giftType == "single") {
                        text += this.termsText.procSingleEchk + "</p>"
                        text += "<p>" + this.termsText.outroSingleEchk + "</p>"
                    } else if (this.giftType == "monthly") {
                        let procText =
                            this.termsText.procAutoEchk.replace(
                                "{CHGPHRASE}",
                                this.$store.getters.formLanguage == "spanish"
                                    ? this.chargePhrase
                                    : this.chargeDate,
                            ) + "</p>"

                        text += procText.replace(
                            "{AMOUNT}",
                            this.transaction.Splits[0].Amount,
                        )
                    } else if (this.giftType == "increaseMonthly") {
                        text +=
                            this.termsText.procIncEchk.replace(
                                "{AMOUNT}",
                                this.transaction.Splits[0].Amount,
                            ) + "</p>"
                    }
                } else {
                    text +=
                        "<p>" +
                        this.termsText.authUserCc.replace(
                            "{CARDTYPE}",
                            this.cardType,
                        ) +
                        " "

                    if (this.giftType == "single") {
                        text += this.termsText.procSingleCc + "</p>"
                    } else if (this.giftType == "monthly") {
                        let procText =
                            this.termsText.procAutoCc.replace(
                                "{CHGPHRASE}",
                                this.$store.getters.formLanguage == "spanish"
                                    ? this.chargePhrase
                                    : this.chargeDate,
                            ) + "</p>"

                        text += procText.replace(
                            "{AMOUNT}",
                            this.transaction.Splits[0].Amount,
                        )
                    } else if (this.giftType == "increaseMonthly") {
                        text +=
                            this.termsText.procIncCc.replace(
                                "{AMOUNT}",
                                this.transaction.Splits[0].Amount,
                            ) + "</p>"
                    }
                }

                if (this.giftType != "single") {
                    text += "<p>" + this.termsText.outroAuto
                    if (this.transaction.Payment.TenderType == "ECHK") {
                        text += " " + this.termsText.outroAutoEchk
                    }
                    text += "</p>"
                }

                return text
            },
        },
        giftType: {
            get() {
                return this.$store.state.giftType
            },
        },
        termsText: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                          .termsConditions
                    : null
            },
        },
        transaction: {
            get() {
                return this.$store.state.transaction
            },
        },
    },
}
</script>
<style scoped></style>
