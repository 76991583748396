<template>
    <div v-if="text">
        <span v-if="availableGiftTypes.length > 1">
            <heading-row :text="text.gift.giftTypeHeader" size="17px" />
            <button-row
                :options="availableGiftTypes"
                :selected-option.sync="giftType"
                :button-color="buttonColor"
                :text-color="textColor"
                btn-margin="0.5em"
                margin-btm="0" />
            <div class="col-12">
                <autogift-help style="display: block" />
            </div>
            <p
                v-if="$v.giftType.$dirty && $v.giftType.$invalid"
                class="error-text text-center">
                {{ text.validation.giftType }}
            </p>
            <p v-else class="error-text placeholder"></p>
        </span>
        <span v-if="giftType">
            <heading-row :text="amountHeader" size="17px" />
            <button-row
                :options="giftAmountOptions"
                :selected-option.sync="amount"
                :button-color="buttonColor"
                :text-color="textColor"
                padding="3em"
                margin-btm="0"
                :show-other="true" />
            <p
                v-if="$v.amount.$dirty && !$v.amount.required"
                class="error-text text-center">
                {{ text.validation.amountMissing }}
            </p>
            <p
                v-else-if="$v.amount.$dirty && !$v.amount.minValue"
                class="error-text text-center">
                {{ text.validation.amountLowPrefix }} ${{ minAmount }}
            </p>
            <p
                v-else-if="$v.amount.$dirty && $v.amount.$invalid"
                class="error-text text-center">
                {{ text.validation.amountInvalid }}
            </p>
            <p v-else class="error-text placeholder"></p>
        </span>
    </div>
</template>
<script>
import buttonRow from "../inputs/ButtonRow.vue"
import headingRow from "../text/HeadingRow.vue"
import autogiftHelp from "../sections/AutogiftHelpText.vue"

export default {
    name: "GiftSection",
    components: { autogiftHelp, buttonRow, headingRow },
    props: [],
    data: function () {
        return {
            defaultGiftAmountOptions: [
                { label: "$20", value: 20 },
                { label: "$30", value: 30 },
                { label: "$40", value: 40 },
            ],
        }
    },
    computed: {
        accentColor: {
            get() {
                return this.design ? this.design.theme.accentColor : null
            },
        },
        amount: {
            get() {
                return this.$store.state.transaction.Splits[0].Amount
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["Splits", "Amount"],
                    value: value,
                })
            },
        },
        amountHeader: {
            get() {
                return this.giftAmountOptions.length > 0
                    ? this.giftType == "increaseMonthly"
                        ? this.text.gift.amountHeaderIncrease
                        : this.text.gift.amountHeader
                    : this.text.gift.amountHeaderOtherOnly
                      ? this.text.gift.amountHeaderOtherOnly
                      : this.text.gift.amountHeader
            },
        },
        autoSelectGiftDefault: {
            get() {
                return this.$store.state.recipe.features.autoSelectGiftDefault
            },
        },
        availableGiftTypes: {
            get() {
                var giftTypeOptions = []

                if (
                    this.$store.state.recipe.features.amountOptions.some(
                        (o) => o.giftType === "single",
                    )
                ) {
                    giftTypeOptions.push({
                        label: this.text.gift.giftTypes.single,
                        value: "single",
                    })
                }
                if (
                    this.$store.state.recipe.features.amountOptions.some(
                        (o) => o.giftType === "monthly",
                    )
                ) {
                    giftTypeOptions.push({
                        label: this.text.gift.giftTypes.monthly,
                        value: "monthly",
                    })
                }
                if (
                    this.$store.state.recipe.features.amountOptions.some(
                        (o) => o.giftType === "increaseMonthly",
                    )
                ) {
                    giftTypeOptions.push({
                        label: this.text.gift.giftTypes.increaseMonthly,
                        value: "increaseMonthly",
                    })
                }

                if (giftTypeOptions.length == 1) {
                    this.$store.commit(
                        "updateGiftType",
                        giftTypeOptions[0].value,
                    )
                }

                return giftTypeOptions
            },
        },
        buttonColor: {
            get() {
                return this.design ? this.design.theme.buttonColor : null
            },
        },
        buttonTextColor: {
            get() {
                return this.design && this.design.theme.buttonTextColor
                    ? this.design.theme.buttonTextColor
                    : null
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        giftAmountOptions: {
            get() {
                if (
                    !this.$store.state.recipe.features.amountOptions ||
                    !this.$store.state.recipe.features.amountOptions.some(
                        (x) => x.giftType == this.giftType,
                    )
                ) {
                    return this.defaultGiftAmountOptions
                }

                var amountsForType =
                    this.$store.state.recipe.features.amountOptions.find(
                        (x) => x.giftType == this.giftType,
                    ).amounts
                var amounts = []
                amountsForType
                    .filter((x) => x && x > 0)
                    .forEach((x) => amounts.push({ label: "$" + x, value: x }))
                return amounts
            },
        },
        giftType: {
            get() {
                return this.$store.state.giftType
                    ? this.$store.state.giftType
                    : this.autoSelectGiftDefault
                      ? "single"
                      : null
            },
            set(value) {
                this.$store.commit("updateGiftType", value)
            },
        },
        isEmbedded() {
            return this.$store.state.isEmbedded
        },
        minAmount: {
            get() {
                return import.meta.env.VITE_MIN_AMOUNT
            },
        },

        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
        textColor: {
            get() {
                return this.buttonTextColor ? this.buttonTextColor : "#fff"
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
    },
    beforeMount() {
        if (this.autoSelectGiftDefault) {
            this.$store.commit("updateGiftType", "single")
            this.$store.commit("updateTransaction", {
                propLocation: ["Splits", "Amount"],
                value: this.giftAmountOptions[0].value,
            })
        }
    },
    methods: {
        selectType: function (type) {
            this.giftType = type
        },
        setTouched: function (field) {
            this.$v[field].$touch()
        },
    },
}
</script>
<style scoped></style>
