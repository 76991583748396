<template>
    <span>
        <component
            :is="template"
            v-if="template && formState == 'Ready'"
            :style="cssVars" />
        <clip-loader
            v-else-if="formState == 'Polling'"
            :loading="true"
            color="white"
            size="100px"
            style="
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            " />
    </span>
</template>
<script>
import templates from "../templates/index.js"
import clipLoader from "vue-spinner/src/ClipLoader.vue"

export default {
    name: "GivingPage",
    components: {
        ...templates,
        clipLoader,
    },
    data: function () {
        return {
            formState: "Loading",
            isRecaptchaReady: false,
            pageValidationAttempts: 0,
            pageValidationIntervalId: null,
        }
    },
    computed: {
        cssVars() {
            return {
                "--color":
                    (this.isEmbedded && "#0000EE") ||
                    this.recipe.design.theme.linkColor ||
                    "#0000EE",
            }
        },
        isEmbedded() {
            return this.$store.state.isEmbedded
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        template: {
            get() {
                return this.isEmbedded
                    ? "embedTemplate"
                    : this.recipe
                      ? this.recipe.general.template + "Template"
                      : null
            },
        },
        text: {
            get() {
                return this.$store.state.text ? this.$store.state.text : null
            },
            set(value) {
                this.$store.state.text = value
            },
        },
    },
    created: async function () {
        // Check with the backend to see if recaptcha is enabled, async
        var recaptchaEnabledPromise = this.$store.dispatch("recaptchaEnabled")

        // Log a dataLayer event so that GA receives a pageview tag
        if (dataLayer) dataLayer.push({ event: "Form Ready" })
        else
            setTimeout(() => {
                if (dataLayer) dataLayer.push({ event: "Form Ready" })
            }, 5000)

        // Validate recaptcha token for page load, but abort the check if we learn recaptcha is disabled
        this.pageValidationIntervalId = window.setInterval(
            function () {
                this.validatePageLoad(recaptchaEnabledPromise)
            }.bind(this),
            100,
        )
        // Run the first check right away
        this.validatePageLoad(recaptchaEnabledPromise)

        this.$store.commit("loadPremium", this.$route.query)
        this.$store.commit("loadVariantKeys", this.$route.query)

        if (Object.keys(this.$route.query).length > 0)
            this.$store.commit("loadQueryParams", this.$route.query)
    },
    methods: {
        validatePageLoad(recaptchaEnabledPromise) {
            this.pageValidationAttempts++

            if (this.$store.state.recaptchaEnabled === false && this.text) {
                this.formState = "Ready"
                window.clearInterval(this.pageValidationIntervalId)
                return
            }

            if (typeof grecaptcha !== "undefined" && !this.isRecaptchaReady) {
                grecaptcha.ready(() => {
                    // Once recaptcha is ready, retrieve and validate the token, but only if recaptcha is enabled
                    recaptchaEnabledPromise.then((r) => {
                        if (r.data === true) {
                            // Validate token
                            this.$store
                                .dispatch(
                                    "getRecaptchaToken",
                                    "givingpage_load",
                                )
                                .then((token) =>
                                    this.$store.dispatch("verifyToken", {
                                        token,
                                        action: "givingpage_load",
                                    }),
                                )
                        }
                    })
                })
                this.isRecaptchaReady = true
            }

            if (!this.text) {
                this.text = JsonText
            }

            if (this.text && this.isRecaptchaReady) {
                this.formState = "Ready"
                window.clearInterval(this.pageValidationIntervalId)
                return
            }

            if (this.pageValidationAttempts >= 50) {
                window.clearInterval(this.pageValidationIntervalId)
                this.formState = "Error"
                this.$router.push({
                    name: "error",
                    params: this.$router.currentRoute.params,
                    query: this.$router.currentRoute.query,
                })
                return
            } else if (this.pageValidationAttempts == 10)
                this.formState = "Polling"
        },
    },
}
</script>
<style>
.error-text {
    color: red;
    font-size: 14px;
    margin-bottom: -0.1em;
}

.error-text.placeholder:before {
    content: "\200b";
}

a {
    color: var(--color) !important;
}

input[type="checkbox"] {
    filter: grayscale(1);
}
</style>
