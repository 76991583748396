import Vue from "vue"
import Router from "vue-router"
import store from "./store.js"
import givingPage from "./views/GivingPage.vue"
import confirmationPage from "./views/ConfirmationPage.vue"
import errorPage from "./views/ErrorPage.vue"

Vue.use(Router)

const basePath =
    document.location.pathname.slice(1).split("/", 1)[0] == "givingapp"
        ? "/givingapp"
        : "/"
export default new Router({
    mode: "history",
    base: basePath,
    routes: [
        {
            path: "/:id",
            name: "default",
            component: givingPage,
            beforeEnter: async (to, from, next) => {
                // Redirect requests that do not supply a form id or alias
                if (!to.params.id) {
                    store.dispatch("redirectTo404")
                    return
                }

                // If the embedded param is given, then set embedded flag
                if (to.query.embed && to.query.embed.toLowerCase() == "true")
                    store.commit("setIsEmbedded", true)

                // Get the form recipe, the store will decide if we need to retrieve it by id or alias
                // The first time this route is accessed the recipe will be fetched
                //      If a campaign is found in the recipe the route will be updated and resolved a second time by vue-router (not by the browser),
                //          the recipe will not need to be loaded a second time and no other asset loading will be needed.
                //      If no campaign is found in the recipe the form will continue to load
                if (!store.state.recipe) {
                    await store.dispatch("getRecipe", {
                        id: to.params.id,
                        preview: to.query.preview,
                        premium: to.query.p,
                    })
                } else {
                    store.commit("loadRecipe", store.state.form)
                }

                next()
            },
        },
        {
            path: "/:id/confirmation",
            name: "confirmation",
            component: confirmationPage,
            // Only allow users to arrive at this page when sent there from the default page
            beforeEnter: (to, from, next) => {
                if (from.name != "default")
                    next({ name: "default", params: to.params })
                else next()
            },
        },
        {
            path: "/:id/error",
            name: "error",
            component: errorPage,
            // Only allow users to arrive at this page when sent there from the default page
            // The prevents random browsing and makes it possible to refresh the error page to try and reload the form
            beforeEnter: (to, from, next) => {
                if (from.name != "default") {
                    next({
                        name: "default",
                        params: to.params,
                        query: to.query,
                    })
                } else next()
            },
        },
        {
            // strip off any non-query params that extend beyond the id, but preserve legit query params
            path: "/:id/*",
            redirect: (to) => {
                return { path: "/:id", query: to.query }
            },
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to == "confirmation" || to == "error") return { x: 0, y: 0 }

        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})
