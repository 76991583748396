<template>
    <div class="row justify-content-center" style="margin-bottom: 1em">
        <!-- eslint-disable vue/no-v-html -->
        <p
            :style="{
                color: color,
                fontWeight,
                fontSize,
                'text-align': alignment,
            }"
            v-html="compiledText" />
        <!-- eslint-enable vue/no-v-html -->
    </div>
</template>
<script>
export default {
    name: "MarkdownRow",
    props: {
        text: {
            type: String,
            default: function () {
                return ""
            },
        },
        color: {
            type: String,
            default: function () {
                return "black"
            },
        },
        size: {
            type: String,
            default: function () {
                return "16px"
            },
        },
        weight: {
            type: String,
            default: function () {
                return "400"
            },
        },
        align: {
            type: String,
            default: function () {
                return "left"
            },
        },
    },
    computed: {
        alignment: {
            get() {
                return this.align ? this.align : "left"
            },
        },
        textColor: {
            get() {
                return this.color ? this.color : "black"
            },
        },
        compiledText: {
            get() {
                return this.$store.getters.compileMarkdown(
                    this.replaceVariables(this.text),
                )
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        fontSize: {
            get() {
                return this.size ? this.size : "16px"
            },
        },
        fontWeight: {
            get() {
                return this.weight ? this.weight : "400"
            },
        },
    },
    methods: {
        replaceVariables: function (text) {
            if (text) {
                return text
                    .split("{")
                    .map((p) => {
                        if (p == "") return p
                        else if (p.indexOf("}") > -1) {
                            var split = p.split("}")
                            split[0] =
                                this.variantKeys[split[0].toUpperCase()] ||
                                "&nbsp;"
                            return split.join("")
                        } else return p
                    })
                    .join("")
            }
            return text
        },
    },
}
</script>
<style scoped>
:deep(p) {
    margin-bottom: 0;
}
</style>
