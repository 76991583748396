﻿import addressSection from "./Address.vue"
import additionalInfoSection from "./AdditionalInfo.vue"
import blurbSection from "./Blurb.vue"
import errorSection from "./Error.vue"
import giftSection from "./Gift.vue"
import paymentSection from "./Payment.vue"
import submitSection from "./Submit.vue"
import premiumSection from "./Premium.vue"
import autogiftHelpTextSection from "./AutogiftHelpText.vue"
import footerSection from "./Footer.vue"
import ddrpStationSection from "./DdrpStation.vue"

export default {
    addressSection,
    additionalInfoSection,
    autogiftHelpTextSection,
    blurbSection,
    errorSection,
    giftSection,
    paymentSection,
    premiumSection,
    footerSection,
    submitSection,
    ddrpStationSection,
}
