<template>
    <div style="height: 100%">
        <div class="justify-content-center" style="height: 100%">
            <div v-if="recipe" id="center-panel" :style="cssVars">
                <div
                    v-if="adminView"
                    class="row admin-view justify-content-center"
                    :style="cssVars">
                    <span
                        >This is a preview form that cannot be submitted. Please
                        don't copy this URL for any other purpose.</span
                    >
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-9 col-md-11">
                        <giftSection class="section" style="margin-bottom: 0" />
                        <paymentSection class="section" />
                        <addressSection
                            :enable-zip-lookup="true"
                            class="section" />

                        <premiumSection
                            v-if="premium && displayPremium"
                            class="section" />
                        <submitSection class="section" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sections from "../sections/index.js"

export default {
    name: "EmbedTemplate",
    components: {
        ...sections,
    },
    data: function () {
        return {
            displayPremium: true,
        }
    },
    computed: {
        adminView: {
            get() {
                return this.$store.state.adminView
            },
        },
        cssVars: {
            get() {
                return {
                    "--header-height": "auto",
                    "--img-height": "auto",
                    "--img2-height": "auto",
                    "--img-margin": "auto",
                    "--col-background": "#f4f6f9",
                    "--accent-color": "darkgrey",
                    "--button-color": "black",
                    "--top-bar-height": "0",
                }
            },
        },
        isPremiumSelected: {
            get() {
                return this.$store.state.isPremiumSelected
            },
        },
        premium: {
            get() {
                return this.$store.getters.premium
            },
        },
        premiumLocation: {
            get() {
                return this.premium
                    ? this.premium.layout.location
                    : "afterBlurb"
            },
        },
        premiumUnavailable: {
            get() {
                return this.$store.state.premiumUnavailable
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
    },
    watch: {
        premiumUnavailable: function (newVal, oldVal) {
            //if premium bottom and not selected then hide
            if (
                newVal &&
                !this.isPremiumSelected &&
                this.premiumLocation == "bottom"
            )
                this.displayPremium = false
            else if (oldVal && !newVal) this.displayPremium = true
        },
    },
}
</script>
<style scoped>
#center-panel {
    background-color: var(--col-background);
    overflow-x: hidden;
}

.formStep {
    display: inline;
    position: center;
    width: 500px;
}

/*@media (max-width: 767px) {
        .img-div {
            height: var(--img-height);
        }
    }*/
@media (min-width: 768px) {
    #header-graphic,
    #header-graphic-in-col {
        height: var(--header-height);
    }

    #header-graphic-in-col {
        margin-top: var(--img-margin);
    }

    #main-graphic {
        max-height: var(--img-height);
        margin-top: var(--img-margin);
        margin-bottom: var(--img-margin);
    }
}

#header-graphic,
#header-graphic-in-col {
    max-width: 100%;
}

#main-graphic {
    max-width: 100%;
}

#main-graphic-column {
    padding-left: 0px;
    padding-right: 0px;
}

@media (max-width: 767px) {
    #header-graphic,
    #header-graphic-in-col {
        max-height: 50px;
        margin-top: 5px;
    }
}

@media (min-width: 768px) {
    #secondary-graphic {
        height: var(--img2-height);
    }
}

.logo-div {
    padding: 10px 15px 10px 15px;
}

#secondary-graphic {
    max-width: 100%;
}

#quote-row {
    background-color: var(--accent-color);
    padding: 10px 40px 0px 40px;
    margin-bottom: 0;
}

.section {
    margin-top: 1em;
    margin-bottom: 1em;
}

.top-bar {
    background-color: var(--accent-color);
    min-height: var(--top-bar-height);
    margin-bottom: 0px;
}

.admin-view {
    color: white;
    background-color: red;
}

.admin-view > a {
    color: white;
}

:deep(a) {
    color: var(--accent-color);
}

:deep(a:hover) {
    text-decoration-line: underline;
}

:deep(.form-control),
:deep(.vs__dropdown-toggle) {
    border-width: 1px;
    border-style: solid;
    border-color: var(--button-color);
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    #center-panel {
        background-color: ghostwhite;
    }

    .img-div {
        height: auto;
    }

    :deep(a) {
        color: #003b5c;
    }

    :deep(.form-control),
    :deep(.vs__dropdown-toggle) {
        border-color: #003b5c;
    }
}
</style>
