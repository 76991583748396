<template>
    <div class="row justify-content-center" :style="cssVars">
        <button
            v-for="option in options"
            :key="option.value"
            type="button"
            class="btn"
            :style="cssVars"
            :class="{
                active: selection === option.value,
                themed: !isEmbedded && buttonColor,
                'btn-outline-primary': !isEmbedded,
                'btn-secondary': isEmbedded,
            }"
            @click="makeSelection(option.value)">
            {{ option.label }}
        </button>
        <button
            v-if="showOther"
            id="other-btn"
            type="button"
            class="btn"
            :style="cssVars"
            :class="{
                active: selection === 'Other',
                themed: !isEmbedded && buttonColor,
                'btn-outline-primary': !isEmbedded,
                'btn-secondary': isEmbedded,
            }"
            @click="makeSelection('Other')">
            <span
                v-show="selection !== 'Other' && otherValue === '$'"
                id="other-span"
                >{{ otherText }}</span
            >
            <input
                v-show="selection === 'Other' || otherValue !== '$'"
                id="other-input"
                ref="otherinput"
                v-model="otherValue"
                :style="cssVars"
                type="text"
                :class="{
                    active: selection === 'Other',
                    themed: !isEmbedded && buttonColor,
                }"
                @blur="cleanUpOther" />
        </button>
    </div>
</template>
<script>
export default {
    name: "ButtonRow",
    // Notes on component props:
    //  - showOther set to a non-null, non-falsy, value causes the 'Other' field to be displayed. At present it accepts valid dollar amounts only.
    props: {
        /** Should be an array of objects with props 'key' and 'value'.
         * 'Value' should be in the format that you wanted returned (ie,
         * float for amounts) */
        options: {
            type: Array,
            default: function () {
                return []
            },
        },
        selectedOption: {
            type: [String, Number],
            default: function () {
                return null
            },
        },
        showOther: {
            type: [Boolean, Number],
            default: function () {
                return null
            },
        },
        buttonColor: {
            type: String,
            default: function () {
                return ""
            },
        },
        textColor: {
            type: String,
            default: function () {
                return "#fff"
            },
        },
        padding: {
            type: String,
            default: function () {
                return "0.75em"
            },
        },
        btnMargin: {
            type: String,
            default: function () {
                return "1em"
            },
        },
        marginBtm: {
            type: String,
            default: function () {
                return "1em"
            },
        },
    },
    data: function () {
        return {
            otherValue: "$",
            selection: this.selectedOption || null,
        }
    },
    computed: {
        cssVars: {
            get() {
                return {
                    "--btn-color": !this.isEmbedded ? this.buttonColor : null,
                    "--text-color":
                        !this.isEmbedded && this.textColor
                            ? this.textColor
                            : "#fff",
                    "--btn-padding": this.padding ? this.padding : "0.75em",
                    "--btn-margin": this.btnMargin ? this.btnMargin : "1em",
                    "--margin-bottom": this.marginBtm ? this.marginBtm : "1em",
                }
            },
        },
        isEmbedded() {
            return this.$store.state.isEmbedded
        },
        otherText: {
            get() {
                return this.options.length == 0
                    ? this.text.gift.amountOtherOnly
                    : this.text.gift.amountOther
            },
        },
        text: {
            get() {
                return this.$store.state.text[this.$store.getters.formLanguage]
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
    },
    watch: {
        otherValue: function (newValue, oldValue) {
            newValue = newValue.trim()

            // Keep dollar sign at the beginning
            var dollar = newValue.lastIndexOf("$")
            if (dollar > 0) newValue = newValue.substring(dollar)
            else if (dollar == -1) newValue = "$" + newValue

            // Prevent updates that result in non-decimal values
            // Prevent amounts over 60k
            // Check for extra decimals or decimal places
            var amt = parseFloat(newValue.substring(1))
            var ix = newValue.indexOf(".")
            if (
                !/^[\d.]*$/.test(newValue.substring(1)) ||
                amt > 60000 ||
                ix != newValue.lastIndexOf(".") ||
                (ix > 0 && newValue.length > ix + 3)
            ) {
                this.otherValue = oldValue
                return
            }

            var decimalIx = newValue.indexOf(".")
            if (
                decimalIx == 0 ||
                (decimalIx > 0 && newValue[decimalIx - 1] === "$")
            ) {
                newValue = newValue.replace(".", "0.")
            }

            this.otherValue = newValue
            if (this.selection === "Other")
                this.$emit("update:selectedOption", isNaN(amt) ? null : amt)
        },
        selection: function (newSelection) {
            if (newSelection === "Other") {
                var amt = parseFloat(this.otherValue.substring(1))
                this.$emit("update:selectedOption", isNaN(amt) ? null : amt)
                this.$nextTick(() => {
                    this.$refs.otherinput.focus()
                    this.$refs.otherinput.setSelectionRange(
                        this.otherValue.length,
                        this.otherValue.length,
                    )
                })
            } else this.$emit("update:selectedOption", newSelection)
        },
    },
    methods: {
        cleanUpOther: function () {
            if (
                this.otherValue.trim() === "" ||
                this.otherValue.trim() === "$"
            ) {
                this.selection = null
                this.otherValue = "$"

                this.setTouched("amount")
                return
            }

            var parsedAmt = parseFloat(this.otherValue.replace("$", ""))

            // Set decimal precision as appropriate
            if (parsedAmt % 1 === 0) {
                this.otherValue = "$" + parsedAmt.toFixed(0)
            } else {
                this.otherValue = "$" + parsedAmt.toFixed(2)
            }

            this.setTouched("amount")
        },
        makeSelection: function (selection) {
            this.selection = selection
        },
        setTouched: function (field) {
            this.$v[field].$touch()
        },
    },
}
</script>
<style scoped>
#other-btn {
    width: 123.88px;
    height: 38px;
    padding: 6px 10px 6px 10px;
}
#other-btn:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

#other-input {
    background-color: transparent;
    border-style: none;
    font-size: 17px;
    margin-top: -6px;
    text-align: center;
    width: 100px;
}
#other-input.themed {
    color: var(--btn-color);
}
#other-input.themed:active,
#other-input.themed.active,
#other-input.themed:focus,
#other-input.themed:hover {
    outline-color: transparent;
    outline-style: none;
    /*background-color: var(--btn-color);
        color: var(--text-color);*/
}

#other-span {
    padding-top: 6px;
}

.row {
    margin-top: 0.5em;
    margin-bottom: var(--margin-bottom);
}

.row > .btn {
    margin: 0.5em var(--btn-margin) 0.5em var(--btn-margin);
    padding-left: var(--btn-padding);
    padding-right: var(--btn-padding);
}

.themed.btn-outline-primary {
    color: var(--btn-color);
    border-color: var(--btn-color);
}
.themed.btn-outline-primary.active,
.themed.btn-outline-primary:active,
.themed.btn-outline-primary:hover,
.themed.btn-outline-primary.active #other-input.themed,
.themed.btn-outline-primary:active #other-input.themed,
.themed.btn-outline-primary:hover #other-input.themed {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    #other-input.themed {
        color: #003b5c;
    }

    .row {
        margin-top: 0.5em;
        margin-bottom: 1em;
    }

    .row > .btn {
        margin: 0.5em 1em 0.5em 1em;
        padding-left: 0.75em;
        padding-right: 0.75em;
    }

    .themed.btn-outline-primary {
        color: #003b5c;
        border-color: #003b5c;
    }
    .themed.btn-outline-primary.active,
    .themed.btn-outline-primary:active,
    .themed.btn-outline-primary:hover,
    .themed.btn-outline-primary.active #other-input.themed,
    .themed.btn-outline-primary:active #other-input.themed,
    .themed.btn-outline-primary:hover #other-input.themed {
        color: #fff;
        background-color: #003b5c;
        border-color: #003b5c;
    }
}
</style>
