const FLAG_SAMPLED = 0b00000001

/**
 * W3C Trace Context
 */
export class TraceContext {
    /**
     * @param {string} traceparent - String representing a W3C traceparent.
     */
    constructor(traceparent) {
        if (!traceparent) {
            this.version = "00"
            this.traceId = this._getRandomByteArray(16)
                .map(this._toHexString)
                .join("")
            this.parentId = this._getRandomByteArray(8)
                .map(this._toHexString)
                .join("")
            // TODO: Allow tuning the sample rate.
            const sampleRate = 1.0
            this.flags = this._toHexString(
                Math.random() <= sampleRate ? FLAG_SAMPLED : 0,
            )
        } else {
            const parsed = this._parseTraceParent(traceparent)
            for (const key in parsed) {
                this[key] = parsed[key]
            }
        }
    }

    /**
     * @private
     * @param {string} traceparent - W3C traceparent string.
     * @returns {{ version: string , traceId: string, parentId: string, flags: string }}
     */
    _parseTraceParent(traceparent) {
        const parts = traceparent.split("-")
        return {
            version: parts[0],
            traceId: parts[1],
            parentId: parts[2],
            flags: parts[3],
        }
    }

    /** @typedef {number} Byte */

    /**
     * @private
     * @param {number} length - Length of the byte array to create.
     * @returns {Array<Byte>}
     */
    _getRandomByteArray(length) {
        return Array.from({ length }).map(() => Math.floor(Math.random() * 255))
    }

    /**
     * Converts number to hex string.
     * @private
     * @param {number} num
     * @returns {string} - Hexidecimal representation of a number.
     */
    _toHexString(num) {
        if (num === null || num === undefined) return num
        return num.toString(16).padStart(2, "0")
    }

    /**
     * Get the root trace information from the currently loaded page.
     * @returns {TraceContext}
     */
    static getRootContext() {
        if (typeof document !== "undefined") {
            const element = document.head.querySelector(
                'meta[name="traceparent"]',
            )
            if (element) {
                return new TraceContext(element.content)
            }
        } else return self.traceparent
    }

    /**
     * Create a new span from the current trace.
     * @returns {TraceContext}
     */
    createSpan() {
        const span = new TraceContext(this.toString())
        // TODO: This will take the SAMPLED flag from the parent; consider being
        // able to create a new span with a different sample setting.
        span.parentId = this._getRandomByteArray(8)
            .map(this._toHexString)
            .join("")
        return span
    }

    /**
     * Returns the string version of the traceparent.
     * @returns {string}
     */
    toString() {
        return `${this.version}-${this.traceId}-${this.parentId}-${this.flags}`
    }
}

export function init() {
    // Store the traceparent for this page load in a <meta> tag (if it doesn't
    // already exist) so it can be accessed globally in the application.
    if (typeof document !== "undefined") {
        const traceparent = document.head.querySelector(
            'meta[name="traceparent"]',
        )
        if (!traceparent) {
            const traceParentTag = document.createElement("meta")
            traceParentTag.name = "traceparent"
            traceParentTag.content = new TraceContext().toString()
            document.head.appendChild(traceParentTag)
        }
    } else {
        self.traceparent = new TraceContext()
    }
}
