<template>
    <div>
        <!-- eslint-disable vue/no-v-html -->
        <p id="heading" :style="cssVars" v-html="blurbHeadingText"></p>
        <p id="blurb" :style="cssVars" v-html="blurbText"></p>
        <!-- eslint-enable vue/no-v-html -->
    </div>
</template>
<script>
import domPurify from "dompurify"
import { marked } from "marked"

export default {
    name: "BlurbSection",
    computed: {
        blurbText: {
            get() {
                return this.design
                    ? this.$store.getters.compileMarkdown(
                          this.replaceVariables(this.design.blurb),
                      )
                    : null
            },
        },
        blurbAlignment: {
            get() {
                return this.design ? this.design.blurbAlignment : "left"
            },
        },
        blurbHeadingText: {
            get() {
                return this.design
                    ? this.$store.getters.compileMarkdown(
                          this.replaceVariables(this.design.blurbHeading),
                      )
                    : null
            },
        },
        blurbHeadingAlignment: {
            get() {
                return this.design ? this.design.blurbHeadingAlignment : "left"
            },
        },
        cssVars: {
            get() {
                return {
                    "--heading-align": this.blurbHeadingAlignment,
                    "--blurb-align": this.blurbAlignment,
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        variantKeys: {
            get() {
                return this.$store.state.variantKeys
            },
        },
    },
    methods: {
        compileMarkdown: function (input) {
            return marked(domPurify.sanitize(input).toString())
        },
        replaceVariables: function (text) {
            if (text) {
                return text
                    .split("{")
                    .map((p) => {
                        if (p == "") return p
                        else if (p.indexOf("}") > -1) {
                            var split = p.split("}")
                            split[0] =
                                this.variantKeys[split[0].toUpperCase()] ||
                                "&nbsp;"
                            return split.join("")
                        } else return p
                    })
                    .join("")
            }
            return text
        },
    },
}
</script>
<style scoped>
:deep(blockquote p) {
    padding: 0px 0px 15px;
    font-family: Georgia, serif;
    line-height: 30.8px;
    padding: 10px 20px;
    border-left: 5px solid #eee;
}

#blurb {
    text-align: var(--blurb-align);
}

#heading {
    text-align: var(--heading-align);
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    #blurb {
        text-align: left;
    }

    #heading {
        text-align: center;
    }
}
</style>
