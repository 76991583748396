import { init as tracingInit } from "./tracing.js" // Initialize traceparent
tracingInit()

import "bootstrap/dist/css/bootstrap.min.css"
import Vue from "vue"
import App from "./App.vue"
import router from "./router.js"
import store from "./store.js"
import Vuelidate from "vuelidate"
import VueMask from "v-mask"
import Rollbar from "vue-rollbar"

Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.config.productionTip = false

// Only log rollbar errors for prod and epiqa. Make a point to filter out msqaap errors depite the production build deployed there.
var environment
if (import.meta.env.MODE === "production")
    environment = window.location.host.includes("msqaap") ? null : "production"
else if (window.location.host.includes("epiqa")) environment = "epiqa"

if (environment) {
    Vue.use(Rollbar, {
        accessToken: "fc6c0dede5f742d798baf5dc7db9cd3a", // post_client_item token
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        telemetryScrubber: function (input) {
            return input.classes.includes("scrub")
        },
        environment: environment,
        payload: {
            client: {
                javascript: {
                    //code_version: '1.0',
                    source_map_enabled: true,
                    guess_uncaught_frames: true,
                },
            },
        },
    })

    Vue.config.errorHandler = function (err) {
        Vue.rollbar.error(err)
        throw err
    }
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")
