<template>
    <div class="container" style="height: 100%" :style="cssVars">
        <div class="row justify-content-center" style="height: 100%">
            <div
                v-if="recipe"
                id="center-panel"
                class="col-lg-9 col-lg-10 col-md-11"
                :style="{ backgroundColor: columnBackground }">
                <div class="row justify-content-center">
                    <div
                        v-if="receiptData.TxId > 0 || receiptData.TxId == -1"
                        class="col-xl-9 col-md-10">
                        <br />
                        <br />
                        <h1 v-if="receiptData.giftType == 'increaseMonthly'">
                            {{ text.confirmationPage.mainHeaderNoGift }}
                        </h1>
                        <h1 v-else>
                            {{ text.confirmationPage.mainHeaderWithGift }}
                        </h1>
                        <br />
                        <iframe
                            v-if="videoUrl"
                            class="d-print-none"
                            width="560"
                            height="315"
                            :src="videoUrl"
                            frameborder="0"
                            allowfullscreen
                            allow="autoplay 'none'">
                        </iframe>
                        <br />
                        <br />
                        <div>
                            <h4
                                v-if="
                                    receiptData.giftType == 'increaseMonthly'
                                ">
                                {{ text.confirmationPage.detailsHeaderNoGift }}
                            </h4>
                            <h4 v-else>
                                {{
                                    text.confirmationPage.detailsHeaderWithGift
                                }}
                            </h4>
                            <dl>
                                <dt v-if="receiptData.TxId > 0">
                                    {{ text.confirmationPage.labels.txid }}
                                </dt>
                                <dd v-if="receiptData.TxId > 0">
                                    {{ receiptData.TxId }}
                                </dd>
                                <dt>
                                    {{ text.confirmationPage.labels.txDate }}
                                </dt>
                                <dd>{{ receiptData.giftDate }}</dd>
                                <dt
                                    v-if="
                                        receiptData.giftType !=
                                        'increaseMonthly'
                                    ">
                                    {{ text.confirmationPage.labels.giftType }}
                                </dt>
                                <dd
                                    v-if="
                                        receiptData.giftType !=
                                        'increaseMonthly'
                                    ">
                                    {{
                                        text.confirmationPage.giftType[
                                            receiptData.giftType
                                        ]
                                    }}
                                </dd>
                                <dt
                                    v-if="
                                        receiptData.giftType !=
                                        'increaseMonthly'
                                    ">
                                    {{ text.confirmationPage.labels.amount }}
                                </dt>
                                <dd
                                    v-if="
                                        receiptData.giftType !=
                                        'increaseMonthly'
                                    ">
                                    ${{ receiptData.Amount }}
                                    <span v-if="receiptData.RecurrenceCap == 0">
                                        {{
                                            text.confirmationPage.labels.monthly
                                        }}</span
                                    >
                                </dd>
                                <dt
                                    v-if="
                                        receiptData.giftType ==
                                        'increaseMonthly'
                                    ">
                                    {{ text.confirmationPage.labels.increase }}
                                </dt>
                                <dd
                                    v-if="
                                        receiptData.giftType ==
                                        'increaseMonthly'
                                    ">
                                    ${{ receiptData.Amount }}
                                    {{ text.confirmationPage.labels.monthly }}
                                </dd>
                                <dt>
                                    {{ text.confirmationPage.labels.tender }}
                                </dt>
                                <dd>
                                    <span v-if="receiptData.TenderType == 'OLP'"
                                        >{{
                                            text.confirmationPage.labels
                                                .creditTender
                                        }}
                                        {{ receiptData.acctLastFour }}</span
                                    >
                                    <span
                                        v-if="receiptData.TenderType == 'ECHK'"
                                        >{{
                                            text.confirmationPage.labels
                                                .eftTender
                                        }}
                                        {{ receiptData.acctLastFour }}</span
                                    >
                                </dd>
                                <p
                                    v-if="
                                        receiptData.giftType ==
                                        'increaseMonthly'
                                    ">
                                    {{ text.confirmationPage.increaseReminder }}
                                </p>
                            </dl>
                        </div>
                        <div>
                            <h4>{{ text.confirmationPage.personalHeader }}</h4>
                            <p>
                                {{ receiptData.Donor.FirstName }}
                                {{ receiptData.Donor.LastName }}<br />
                                <span
                                    v-if="
                                        receiptData.Donor.Organization != null
                                    "
                                    >{{ receiptData.Donor.Organization }}<br
                                /></span>
                                {{ receiptData.Donor.Address1 }}<br />
                                {{ receiptData.Donor.City }},
                                {{ receiptData.Donor.State }}
                                {{ receiptData.Donor.Postal }}<br />
                                <span
                                    v-if="
                                        receiptData.Donor.Country != null &&
                                        receiptData.Donor.Country.code != 'US'
                                    "
                                    >{{ receiptData.Donor.Country.label }}<br
                                /></span>
                                {{ formatPhone(receiptData.Donor.Phone) }}<br />
                                {{ receiptData.Donor.Email }}<br />
                            </p>
                        </div>
                        <div>
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                v-if="receiptData.TxId > 0"
                                v-html="text.confirmationPage.helpText"></span>
                            <span
                                v-else
                                v-html="
                                    text.confirmationPage.helpTextNoRefNumber
                                "></span>
                            <!-- eslint-enable vue/no-v-html -->
                            <button
                                class="btn btn-outline-primary no-print"
                                :style="cssVars"
                                :class="{ themed: buttonColor }"
                                style="margin-bottom: 16px"
                                @click="print">
                                {{ text.confirmationPage.labels.print }}
                            </button>
                            <p style="font-size: 12px">
                                {{ text.confirmationPage.legalese }}
                            </p>
                        </div>
                    </div>
                    <div v-if="receiptData.TxId == 0">
                        <br /><br />{{
                            text.confirmationPage.confirmationError
                        }}
                    </div>
                </div>
                <footerSection
                    class="justify-content-center"
                    :hide-contact="true"></footerSection>
            </div>
        </div>
    </div>
</template>
<script>
import footerSection from "../sections/Footer.vue"
export default {
    name: "DefaultConfirmationTemplate",
    components: { footerSection },
    computed: {
        accentColor: {
            get() {
                return this.design ? this.design.theme.accentColor : null
            },
        },
        buttonColor: {
            get() {
                return this.design ? this.design.theme.buttonColor : null
            },
        },
        columnBackground: {
            get() {
                return this.design.theme.columnBackground
            },
        },
        cssVars: {
            get() {
                return {
                    "--accent-color": this.accentColor,
                    "--btn-color": this.buttonColor,
                    "--text-color": this.textColor ? this.textColor : "#fff",
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        drmEmail: {
            get() {
                return (
                    "mailto: DRM@moody.edu?subject=Question%20about%20my%20donation%20-%20Reference%20Number:%20" +
                    this.receiptData.TxId
                )
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        receiptData: {
            get() {
                return this.$store.state.receiptData
            },
        },
        text: {
            get() {
                return this.$store.state.text[this.$store.getters.formLanguage]
            },
        },
        textColor: {
            get() {
                return this.design ? this.design.theme.textColor : null
            },
        },
        videoUrl: {
            get() {
                if (this.recipe.design.videoUrls) {
                    var videoUrlList = this.recipe.design.videoUrls.split(",")
                    var videoIndex = Math.floor(
                        Math.random() * videoUrlList.length,
                    )
                    return videoUrlList[videoIndex]
                }
                return ""
            },
        },
    },
    methods: {
        formatPhone(value) {
            if (!value) return null

            if (
                this.receiptData.Donor.Country.code == "US" ||
                this.receiptData.Donor.Country.code == "CA"
            ) {
                return (
                    "(" +
                    value.slice(0, 3) +
                    ") " +
                    value.slice(3, 6) +
                    "-" +
                    value.slice(-4)
                )
            } else {
                return value
            }
        },
        properCase(value) {
            var word = value.toLowerCase()
            return word[0].toUpperCase() + word.slice(1)
        },
        print() {
            window.print()
        },
    },
}
</script>
<style scoped>
#center-panel {
    overflow: auto;
}

dt {
    float: left;
}

dd {
    margin-left: 180px;
}

:deep(a) {
    color: var(--accent-color);
}

:deep(a:hover) {
    text-decoration-line: underline;
}

.themed.btn-outline-primary {
    color: var(--text-color);
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    opacity: 0.8;
}

.themed.btn-outline-primary:hover {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);
    opacity: 1;
}

.themed.btn-outline-primary.active,
.themed.btn-outline-primary:active {
    color: var(--text-color);
    background-color: var(--btn-color);
    border-color: var(--btn-color);
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    #center-panel {
        /*We have to set a manual width to get IE to honor overfloww: auto*/
        /*The column background won't extend beyond the initial viewport without this*/
        flex: 0 1 80vw;
        overflow: auto;
    }

    :deep(a) {
        color: #003b5c;
    }

    .themed.btn-outline-primary {
        color: #fff;
        border-color: #003b5c;
        background-color: #003b5c;
        opacity: 0.8;
    }

    .themed.btn-outline-primary:hover {
        color: #fff;
        background-color: #003b5c;
        border-color: #003b5c;
        opacity: 1;
    }

    .themed.btn-outline-primary.active,
    .themed.btn-outline-primary:active {
        color: #fff;
        background-color: #003b5c;
        border-color: #003b5c;
    }
}
</style>
